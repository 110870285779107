import React from 'react';
import { connect } from 'react-redux';
import { setRawResult } from 'state/tabs/tab/result/raw/reducer';
import { getSelectedTab } from 'state/selectedTab';

class MatrixResult extends React.Component {
  componentDidMount() {
    this.getStatus();
  }

  getStatus() {
    const {
      fields: { matrixResultStatusUrl },
      setFields,
      settings,
      setResultState,
      request,
      setRawResult,
      selectedTab,
    } = this.props;

    if (matrixResultStatusUrl) {
      fetch(`${matrixResultStatusUrl}?apiKey=${settings.apiKey}`, {
        redirect: 'manual',
      }).then((res) => {
        if (res.status === 0 && res.type === 'opaqueredirect') {
          setFields({
            matrixResultUrl: matrixResultStatusUrl.replace('/status', ''),
            matrixResultStatusUrl: null,
          });
          setResultState({
            isMatrixLoading: false,
          });
          clearTimeout(this.timeoutId);
          request();
        } else {
          return res.text()
        }
      }).then(data => {
        if (data) {
          try {
            const parsedData = JSON.parse(data);
            if (parsedData.error) {
              setFields({
                matrixResultUrl: matrixResultStatusUrl.replace('/status', '')
              });
              setResultState({
                isResultPanelShown: true,
                isMatrixLoading: false
              });
              setRawResult({data: parsedData.error, isError: true, statusCode: parsedData.error.status}, selectedTab);
              clearTimeout(this.timeoutId);
            } else {
              setResultState({
                isResultPanelShown: true,
                isMatrixLoading: true,
              });
              this.timeoutId = setTimeout(this.getStatus.bind(this), 5000);
            }
          } catch(e){

          }
        }
      })
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: getSelectedTab(state),
  };
}

const mapDispatchToProps = {
  setRawResult
};

const matrixResultInputPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(MatrixResult),
};

export default matrixResultInputPlugin;
