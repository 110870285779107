const vector = {
  politicalView: [
    { value: '-none-', label: '-none-' },
    { value: 'ar', label: 'Argentina' },
    { value: 'cy', label: 'Cyprus' },
    { value: 'eg', label: 'Egypt' },
    { value: 'in', label: 'India' },
    { value: 'ke', label: 'Kenya' },
    { value: 'ma', label: 'Morocco' },
    { value: 'pk', label: 'Pakistan' },
    { value: 'ps', label: 'Palestinian Territory' },
    { value: 'ru', label: 'Russia' },
    { value: 'sd', label: 'Sudan' },
    { value: 'rs', label: 'Serbia' },
    { value: 'sr', label: 'Suriname' },
    { value: 'sy', label: 'Syria' },
    { value: 'tz', label: 'Tanzania' },
    { value: 'uy', label: 'Uruguay' },
    { value: 'vn', label: 'Vietnam' },
  ],
  advancedRoads: [
    { value: '-none-', label: '-none-' },
    { value: 'default,advanced_roads', label: 'Advanced Roads' },
  ]
};

export default vector;
