import { getResult } from '../tabs/tab/result/raw/reducer';

export const getIsLeftPanelShown = (state) => state.ui.isLeftPanelShown;
export const getIsHistoryShown = (state) => state.ui.isHistoryShown;
export const getIsNewTabShown = (state) => state.ui.isNewTabShown;
export const getIsOpenInShown = (state) => state.ui.isOpenInShown;
export const getIsAvoidExcludeShown = (state) => state.ui.isAvoidExcludeShown;
export const getIsResultPanelShown = (state) => {
  const result = getResult(state);
  return (
    state.ui.isResultPanelShown &&
    (!!result.raw || result.state.isResultPanelShown)
  );
};
export const getTriggerAutoZoom = (state) => state.ui.triggerAutoZoom;
export const getIsCopyCURL = (state) => state.ui.isCopyCURL;
