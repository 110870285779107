import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from 'shared/radioGroup';
import { Tooltip } from '@here/ref-client-ui';
import './styles/apiUrlType.scss';

class ApiUrlType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false
    };
  }

  onChange = (value) => {
    const {
      setIsCopyCURL,
    } = this.props;
    setIsCopyCURL(value === 'curl');
  };

  copyUrl = async (url, postData) => {
    this.setState({ copied: true });
    const {
      isCopyCURL,
      getSetting,
      isInternal,
    } = this.props;
    
    if (isCopyCURL) {
      let setting = {};
      try {
        setting = await getSetting();
      } catch (e) {}
      const { type, token, scopedToken } = setting;
      
      const method = postData ? 'POST' : 'GET';
      const [host = '', params = ''] = url.split('?');
      const curlParams = params.split('&').map((param) => {
        const [key, value] = param.split(/=(.*)/);
        return `'${key}=${decodeURIComponent(value)}`;
      });
      const newLine = `\\\n`;
      let bearerToken;
      if (type === 'hereToken' || type === 'platformCredential') {
        if ( !isInternal && type === 'hereToken' ) {
          bearerToken = `  -H 'Authorization: Bearer TOKEN'${newLine}`;
        } else {
          bearerToken = `  -H 'Authorization: Bearer ${scopedToken || token}'${newLine}`;
        }
      }

      const curl = `curl -gX ${method} '${host}?'${newLine}` 
      + curlParams.join(`&'${newLine}`) 
      + `'${(postData||bearerToken)?newLine:''}`
      + (postData ? `  -H 'Content-Type: application/json'${newLine}` : '')
      + (bearerToken ? bearerToken : '')
      + (postData ? `  -d '${JSON.stringify(postData, null, 2)}'` : '');
      navigator.clipboard.writeText(curl);
    } else {
      navigator.clipboard.writeText(url);
    }
  }

  mouseLeave = () => {
    this.setState({ copied: false });
  }

  render() {
    const { copied } = this.state;
    const {
      encodedUrl,
      isCopyCURL,
      postData,
    } = this.props;

    const value = isCopyCURL ? 'curl' : 'url';
    const options = [
      {value: 'url', label: 'URL'}, {value: 'curl', label: 'cURL'}
    ];

    const tooltip = (
      <div
        className="rf-api-url-types"
      >
        <lui-default-theme dark="true">
          <RadioGroup
            className="rf-api-url-types__content"
            options={options}
            value={value}
            columns={2}
            onChange={this.onChange}
          />
          <div 
            className="rf-api-url-types__text"
          >
            {copied? 'Copied!' : 'Copy to Clipboard'}
          </div>
        </lui-default-theme>
      </div>
    )
    return (
      <Tooltip 
        tooltip={tooltip}
        placement="top"
      >
        <div 
          className="rf-input__btn-copy rf-api-url-copy-btn"
          onClick={this.copyUrl.bind(this, encodedUrl, postData)}
          onMouseLeave={this.mouseLeave}
        />
      </Tooltip>
    );
  }
}

ApiUrlType.propTypes = {
  style: PropTypes.object,
  postData: PropTypes.object,
  setNotification: PropTypes.func.isRequired,
  getSetting: PropTypes.func.isRequired,
};

export default ApiUrlType;
