import './formatters';
import './mapPlugins';
import './resultPanelPlugins';
import './formViewsPlugins';
import './settingPlugins';
import './autoRefreshPlugins';

import settingsPlugin from './settingsPlugin';

import formViewsPlugin from './formViewsPlugin';
import resultPanelPlugin from './resultPanelPlugin';
import Summary from './resultPanelPlugins/summary/Summary';
import ResultTabs from './resultPanelPlugins/tab/ResultTabs';
import mapPlugin from './mapPlugin';

import marker from './mapPlugins/marker';
import evRoute from './mapPlugins/evRoute';
import routeMatchingRoute from './mapPlugins/routeMatchingRoute';
import contextMenu from './mapPlugins/contextMenu';
import setFields from './mapPlugins/contextMenuItem/setFields';
import copyLatLon from './mapPlugins/contextMenuItem/copyLatLon';
import copyAddress from './mapPlugins/contextMenuItem/copyAddress';
import getMapAttributes from './mapPlugins/contextMenuItem/getMapAttributes';
import inspectLinkMenuItem from './mapPlugins/contextMenuItem/inspectLink';
import rorTooltip from './mapPlugins/rorTooltip';

import checkboxesFormView from './formViewsPlugins/checkboxes/checkboxesFormView';
import inputFormView from './formViewsPlugins/input/inputFormView';
import traceFormView from './formViewsPlugins/trace/traceFormView';
import routingMode from './formViewsPlugins/mode/routingMode';
import licensePlate from './formViewsPlugins/licensePlate/licensePlate';
import inputObjectFormView from './formViewsPlugins/inputObject/inputObjectFormView';
import selectFormView from './formViewsPlugins/select/selectFormView';
import routeTimeFormView from './formViewsPlugins/routeTime/routeTimeFormView';
import RawResponse from './resultPanelPlugins/rawResponse/RawResponse';
import transportMode from './formViewsPlugins/transportMode/transportMode';
import routeMode from './formViewsPlugins/routeMode/routeMode';
import extraParams from './formViewsPlugins/extraparams/extraParams';
import group from './formViewsPlugins/group/group';
import advancedViaFields from './formViewsPlugins/advancedCorridorPoints/advancedViaFields';
import multiplePoints from './formViewsPlugins/multiplePoints/multiplePoints';
import violationMapping from './formViewsPlugins/violationMapping';

import markers from './mapPlugins/markers';
import distanceMeasurement from './mapPlugins/distanceMeasurement';
import ptMarkers from './mapPlugins/ptMarkers';
import OlsPtSummary from './resultPanelPlugins/olsPtSummary/OlsPtSummary';
import Maneuvers from './resultPanelPlugins/maneuvers/Maneuvers';
import MapAttributesResult from './resultPanelPlugins/mapAttributes/MapAttributes';
import OlsPtMeneuvers from './resultPanelPlugins/olsPtManeuvers/OlsPtManeuvers';
import APDetails from './resultPanelPlugins/apDetails/APDetails';
import RMDetails from './resultPanelPlugins/rmDetails/RMDetails';
import TaggedManeuvers from './resultPanelPlugins/taggedManeuvers/TaggedManeuvers';
// import waypoints from './formViewsPlugins/waypoints/waypoints';
import RegionDefiniton from './formViewsPlugins/regionDefinition/regionDefinition';
import SearchRegion from './formViewsPlugins/searchRegion/searchRegion';
import SegmentSpeedCap from './formViewsPlugins/segmentSpeedCap/segmentSpeedCap';
import MapAttributes from './formViewsPlugins/mapAttributes/mapAttributes';
import hlsStartWaypoint from './mapPlugins/contextMenuItem/hlsStartWaypoint';
import hlsEndWaypoint from './mapPlugins/contextMenuItem/hlsEndWaypoint';
import hlsWaypoint from './mapPlugins/contextMenuItem/hlsWaypoint';
import olsMatrixAddPoint from './mapPlugins/contextMenuItem/olsMatrixAddPoint';
import routeMatchingAddPoint from './mapPlugins/contextMenuItem/routeMatchingAddPoint';
import avoidExcludeAreas from './mapPlugins/contextMenuItem/avoidExcludeAreas';
import TabOpener from './resultPanelPlugins/tabOpener/TabOpener';
import matrixRoutes from './mapPlugins/matrixRoutes';
import matrixMarkers from './mapPlugins/matrixMarkers';
import MatrixList from './resultPanelPlugins/olsMatrix/MatrixList';
import CustomizationSearch from './resultPanelPlugins/customizationSearch/CustomizationSearch';
import resultFieldsStore from './formViewsPlugins/olsMatrix/resultFieldsStore';
import regionDefinition from './mapPlugins/RegionDefinition';
import searchRegion from './mapPlugins/SearchRegion';
import circle from './mapPlugins/circle';
import rectangle from './mapPlugins/rectangle';
import polygon from './mapPlugins/polygon';
import polygonList from './mapPlugins/polygonList';
import corridorList from './mapPlugins/corridorList';
import pointsLocationPositions from './mapPlugins/pointsLocationPositions';
import Location from './formViewsPlugins/location/location';

import profileSelect from './formViewsPlugins/olsMatrix/profileSelect';
import avoidExclude from './formViewsPlugins/olsMatrix/avoidExclude';
import rectangleList from './mapPlugins/rectangleList';
import formatterPlugin from './formattersPlugin';
import OlsWaypoint from './formViewsPlugins/olsWaypoint/olsWaypoint';
import OlsEvConsumption from './formViewsPlugins/consumption/olsEvConsumption';
import OlsFuelConsumption from './formViewsPlugins/consumption/olsFuelConsumption';
import Profiling from './resultPanelPlugins/profiling/Profiling';
import IntermodalVia from './formViewsPlugins/intermodalVia/intermodalVia';
import Language from './formViewsPlugins/language/language';

formViewsPlugin.register('checkboxes', checkboxesFormView);
formViewsPlugin.register('input', inputFormView);
formViewsPlugin.register('routeTrace', traceFormView);
formViewsPlugin.register('routingMode', routingMode);
formViewsPlugin.register('licensePlate', licensePlate);
formViewsPlugin.register('inputObject', inputObjectFormView);
formViewsPlugin.register('select', selectFormView);
formViewsPlugin.register('routeTime', routeTimeFormView);
formViewsPlugin.register('transportMode', transportMode);
formViewsPlugin.register('routeMode', routeMode);
formViewsPlugin.register('extraParams', extraParams);
formViewsPlugin.register('group', group);
formViewsPlugin.register('advancedViaFields', advancedViaFields);
formViewsPlugin.register('violationMapping', violationMapping);
// formViewsPlugin.register('waypoints', waypoints);
formViewsPlugin.register('olsWaypoint', OlsWaypoint);
formViewsPlugin.register('olsEvConsumption', OlsEvConsumption);
formViewsPlugin.register('olsFuelConsumption', OlsFuelConsumption);
formViewsPlugin.register('multiplePoints', multiplePoints);
formViewsPlugin.register('regionDefinion', RegionDefiniton);
formViewsPlugin.register('location', Location);
formViewsPlugin.register('searchRegion', SearchRegion);
formViewsPlugin.register('segmentSpeedCap', SegmentSpeedCap);
formViewsPlugin.register('mapAttributes', MapAttributes);
formViewsPlugin.register('resultFieldsStore', resultFieldsStore);
formViewsPlugin.register('profileSelect', profileSelect);
formViewsPlugin.register('avoidExclude', avoidExclude);
formViewsPlugin.register('intermodalVia', IntermodalVia);
formViewsPlugin.register('language', Language);

resultPanelPlugin.register('summary', Summary);
resultPanelPlugin.register('tab', ResultTabs);
resultPanelPlugin.register('rawResponse', RawResponse);
resultPanelPlugin.register('olsPtSummary', OlsPtSummary);
resultPanelPlugin.register('profiling', Profiling);
resultPanelPlugin.register('maneuvers', Maneuvers);
resultPanelPlugin.register('mapAttributes', MapAttributesResult);
resultPanelPlugin.register('olsPtManeuvers', OlsPtMeneuvers);
resultPanelPlugin.register('tabOpener', TabOpener);
resultPanelPlugin.register('matrixList', MatrixList);
resultPanelPlugin.register('customizationSearch', CustomizationSearch);
resultPanelPlugin.register('apDetails', APDetails);
resultPanelPlugin.register('rmDetails', RMDetails);
resultPanelPlugin.register('taggedManeuvers', TaggedManeuvers);

mapPlugin.register('marker', marker);
mapPlugin.register('markers', markers);
mapPlugin.register('distanceMeasurement', distanceMeasurement);
mapPlugin.register('ptMarkers', ptMarkers);
mapPlugin.register('evRoute', evRoute);
mapPlugin.register('routeMatchingRoute', routeMatchingRoute);
mapPlugin.register('contextMenu', contextMenu);
mapPlugin.register('rorTooltip', rorTooltip);
mapPlugin.register('setFields', setFields);
mapPlugin.register('copyLatLon', copyLatLon);
mapPlugin.register('copyAddress', copyAddress);
mapPlugin.register('getMapAttributes', getMapAttributes);
mapPlugin.register('inspectLinkMenuItem', inspectLinkMenuItem);
mapPlugin.register('hlsStartWaypoint', hlsStartWaypoint);
mapPlugin.register('hlsEndWaypoint', hlsEndWaypoint);
mapPlugin.register('hlsWaypoint', hlsWaypoint);
mapPlugin.register('olsMatrixAddPoint', olsMatrixAddPoint);
mapPlugin.register('routeMatchingAddPoint', routeMatchingAddPoint);
mapPlugin.register('avoidExcludeAreas', avoidExcludeAreas);
mapPlugin.register('matrixRoutes', matrixRoutes);
mapPlugin.register('matrixMarkers', matrixMarkers);
mapPlugin.register('regionDefinition', regionDefinition);
mapPlugin.register('searchRegion', searchRegion);
mapPlugin.register('circle', circle);
mapPlugin.register('rectangle', rectangle);
mapPlugin.register('rectangleList', rectangleList);
mapPlugin.register('polygon', polygon);
mapPlugin.register('polygonList', polygonList);
mapPlugin.register('corridorList', corridorList);
mapPlugin.register('pointsLocationPositions', pointsLocationPositions);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mapPlugin,
  resultPanelPlugin,
  formViewsPlugin,
  formatterPlugin,
  settingsPlugin,
};
