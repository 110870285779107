import React from 'react';
import PropTypes from 'prop-types';
import HereMap from 'shared/hereMap/HereMap';
import { getTileLayer, TILE_TYPE_ENGINE_MAP, setJslaPoliticalView, setJslaHarpPoliticalView } from './TilesHelper';
import { DEFAULT_MAP_CENTER, DEFAULT_ZOOM } from '../constants';
import { debounce, isEqual } from 'lodash';
import isFunction from 'lodash/isFunction';
import refClient from '../../../core/refClient';
import { TILE_LIBRARY_TYPES, TILE_TYPES } from '../../../state/map/tiles/constants';
import HereGroup from '../../shared/hereMap/HereGroup';
import HereSearchResults from './HereSearchResults';
import ZoomControls from './ZoomControls';
import HereTrafficLayer from './HereTrafficLayer';
import MapErrorHandler from '../MapErrorHandler';
import { logError } from '../../../utils/splunkLog';
import { getDefaultMapCenter } from '../defaultMapCenterLocalStorage';
import MapE2E from './MapE2E';

class JslaMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.onWindowResizeDebounced = debounce(this.onWindowResize, 500);
    this.tabGroups = [];
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResizeDebounced);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedTab,
      isAutoZoomOn,
      result,
      tabs,
      tilesData,
      styleUrl,
      showTruckTile,
      triggerAutoZoom
    } = this.props;
    const { isActive } = tabs[selectedTab];

    const isResultChanged = !isEqual(result.raw, prevProps.result.raw);
    if (
      isAutoZoomOn &&
      isActive &&
      (isResultChanged ||
        prevProps.selectedTab !== selectedTab ||
        triggerAutoZoom !== prevProps.triggerAutoZoom)
    ) {
      this.autoZoom(prevProps);
    }

    const { map } = this.mapRef.current;
    if (!isEqual(tilesData, prevProps.tilesData)) {
      map.setBaseLayer(getTileLayer(tilesData, styleUrl, showTruckTile));
    }

    if (
      styleUrl !== prevProps.styleUrl &&
      isFunction(map.getBaseLayer().getProvider().setStyle)
    ) {
      if (tilesData.type === TILE_TYPES.VECTORHARP) {
        const style = map
          .getBaseLayer()
          .getProvider()
          .getStyle();

        const enabledFeatures = style.getEnabledFeatures() || [];
        const featureToEnable = {feature: 'vehicle restrictions', mode: 'active & inactive'};
        const featureIndex = enabledFeatures.findIndex(el => el.feature === featureToEnable.feature);
        if (showTruckTile) {
          enabledFeatures.splice(featureIndex, featureIndex === -1 ? 0 : 1, featureToEnable);
        } else {
          enabledFeatures.splice(featureIndex, 1);
        }
        style.setEnabledFeatures(enabledFeatures);
      } else {
        map
          .getBaseLayer()
          .getProvider()
          .setStyle(new window.H.map.Style(styleUrl));
      }
    }
    if ( 
      isFunction(map.getBaseLayer().getProvider().getStyle)
    ) {
      if (tilesData.type === TILE_TYPES.VECTORHARP) {
        setJslaHarpPoliticalView(map, tilesData[tilesData.type].pview )
      } else {
        setJslaPoliticalView(map, tilesData[tilesData.type].pview )
      }
    }
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResizeDebounced);
  }

  onWindowResize = () => {
    const { setMapBounds } = this.props;
    const { map } = this.mapRef.current;
    if (!map) {
      return;
    }
    map.getViewPort().resize();
    const rect = map.getViewModel().getLookAtData().bounds.getBoundingBox();
    setMapBounds({
      top: rect.getTop(),
      right: rect.getRight(),
      bottom: rect.getBottom(),
      left: rect.getLeft(),
      zoom: Math.floor(map.getZoom()),
    });
  };

  onMapViewChange = (e) => {
    const { setMapBounds } = this.props;
    const rect = e.target
      .getViewModel()
      .getLookAtData()
      .bounds.getBoundingBox();
    setMapBounds({
      top: rect.getTop(),
      right: rect.getRight(),
      bottom: rect.getBottom(),
      left: rect.getLeft(),
      zoom: Math.floor(e.target.getZoom()),
    });
  };

  renderTabsComponents = () => {
    const {
      config,
      dispatch,
      tabs,
      selectedTab,
      setResultState,
      bounds,
      setFields,
      addTab,
      setNotification,
      allSettings,
      request,
      tilesData
    } = this.props;
    return tabs.map((tab, tabIndex) => {
      if (!tab.isActive) {
        return null;
      }

      const { current } = this.mapRef;
      let map = current ? current.map : {};
      const { title, isActive, preset } = tab;
      const { type } = tilesData;
      const tabConfigs = config[title];
      const zoomableEls = [];
      const nonZoomableEls = [];
      tabConfigs.map.forEach((mapConfig, index) => {
        try {
          const plugin = refClient.mapPlugin.get(mapConfig.type);
          const Component = plugin[TILE_LIBRARY_TYPES.JSLA];
          if (!Component) {
            return;
          }
          const el = (
            <MapErrorHandler
              key={`${mapConfig.type}_${tabIndex}_${index}_${isActive}`}
            >
              <Component
                tabs={tabs}
                colorPalette={tab.tabColorPalette}
                paramsMapping={tab.paramsMapping}
                dispatch={dispatch}
                fields={tab.fields}
                options={mapConfig.options}
                result={tab.result}
                selectedTab={selectedTab}
                tabIndex={tabIndex}
                setResultState={setResultState}
                setFields={setFields}
                bounds={bounds}
                refClient={refClient}
                addTab={addTab}
                setNotification={setNotification}
                settings={allSettings[title][preset]}
                request={request}
                type={type}
                map={map}
              />
            </MapErrorHandler>
          );
          if (mapConfig.skipAutoZoom) {
            nonZoomableEls.push(el);
          } else {
            zoomableEls.push(el);
          }
        } catch (e) {
          logError({...e, mapConfig});
        }
      });

      const zIndex = tabIndex * -1 + 1000;
      return (
        <React.Fragment key={tabIndex}>
          <HereGroup
            ref={(ref) => {
              this.tabGroups[tabIndex] = ref;
            }}
            options={{ zIndex }}
          >
            {zoomableEls}
          </HereGroup>
          <HereGroup options={{ zIndex }}>{nonZoomableEls}</HereGroup>
        </React.Fragment>
      );
    });
  };

  autoZoom = () => {
    const { selectedTab } = this.props;
    let group = null;
    if (this.tabGroups[selectedTab]) {
      group = this.tabGroups[selectedTab].group;
    } else {
      return;
    }
    const { map } = this.mapRef.current;
    const bounds = group.getBoundingBox();
    const mapBounds = map
      .getViewModel()
      .getLookAtData()
      .bounds.getBoundingBox();
    if (bounds && !mapBounds.containsRect(bounds)) {
      if (
        bounds.getTop() === bounds.getBottom() &&
        bounds.getRight() === bounds.getLeft()
      ) {
        map.setCenter(bounds.getCenter());
      } else {
        map.getViewModel().setLookAtData({ bounds });
      }
    }
  };

  render() {
    const {
      tilesData,
      bounds: { top, right, bottom, left },
      styleUrl,
      trafficOverlays,
      searchData,
      showTruckTile,
    } = this.props;
    const mapProps = {};
    if (top) {
      mapProps.bounds = new window.H.geo.Rect(+top, +left, +bottom, +right);
    }
    const layer = getTileLayer(tilesData, styleUrl, showTruckTile);
    return (
      <HereMap
        key={tilesData.type}
        ref={this.mapRef}
        customDefaultLayer={layer}
        zoom={DEFAULT_ZOOM}
        center={getDefaultMapCenter() || DEFAULT_MAP_CENTER}
        onMapViewChangeEnd={this.onMapViewChange}
        options={{ engineType: TILE_TYPE_ENGINE_MAP[tilesData.type] }}
        {...mapProps}
      >
        <HereSearchResults searchData={searchData} />
        <ZoomControls />
        {this.renderTabsComponents()}
        {trafficOverlays.isActive && (
          <HereTrafficLayer data={trafficOverlays} tilesData={tilesData} />
        )}
        <MapE2E />
      </HereMap>
    );
  }
}

JslaMap.propTypes = {
  config: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  isAutoZoomOn: PropTypes.bool.isRequired,
  setMapBounds: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
  searchData: PropTypes.object.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabs: PropTypes.array.isRequired,
  bounds: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  result: PropTypes.object.isRequired,
  tilesData: PropTypes.object.isRequired,
  addTab: PropTypes.func.isRequired,
  styleUrl: PropTypes.string.isRequired,
  trafficOverlays: PropTypes.object.isRequired,
  triggerAutoZoom: PropTypes.number,
  allSettings: PropTypes.object.isRequired,
  request: PropTypes.func.isRequired,
};

export default JslaMap;
