import React from 'react';
import EvConsumptionComponent from './OlsEvConsumptionComponent';
import PropTypes from 'prop-types';
import utils from 'utils';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import set from 'lodash/set';
import get from 'lodash/get';
import { empty } from './olsElectricPreset';

class ConsumptionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modifyValue: false
    };
  }

  onChange = (key, e) => {
    const { setFields } = this.props;
    setFields({ [key]: utils.extractData(e) });
  };

  onMultiUpdate = (data) => {
    const { setFields } = this.props;
    setFields(data);
    this.setState({ modifyValue: true});
  };

  componentDidUpdate(prevProps) {
    if (get(this.props, 'fields.transportMode') !== get(prevProps, 'fields.transportMode') && this.state.modifyValue) {
      const element = document.getElementsByClassName('rf-consumption');
      element.length && element[0].scrollIntoView();
      this.setState({ modifyValue: false});
    }
  }

  render() {
    const { fields, options = {}, settings } = this.props;
    const {
      makeReachableEnabled = true,
      chargingStationParameters = true,
      chargeAwareRoutingEnabled = true,
      batteryParametersEnabled = true,
      chargingParametersEnabled = true,
      consumptionModelEnabled = true,
      physicalConsumptionParametersEnabled = true,
      baseEVModel = false,
    } = options;
    return (
      <EvConsumptionComponent
        formData={{ fields }}
        makeReachableEnabled={makeReachableEnabled}
        chargeAwareRoutingEnabled={chargeAwareRoutingEnabled}
        batteryParametersEnabled={batteryParametersEnabled}
        chargingParametersEnabled={chargingParametersEnabled}
        chargingStationParameters={chargingStationParameters}
        consumptionModelEnabled={consumptionModelEnabled}
        physicalConsumptionParametersEnabled={physicalConsumptionParametersEnabled}
        baseEVModel={baseEVModel}
        settings={settings}
        onChange={this.onChange}
        onMultiUpdate={this.onMultiUpdate}
      />
    );
  }
}

ConsumptionView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  settings: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

const evParamMask = /^ev\[.+]$/;

const evParamsMap = {
  'consumptionmodel': 'consumptionModel',
  'ev[makereachable]': 'ev[makeReachable]',
  'ev[connectortypes]': 'ev[connectorTypes]',
  'ev[freeflowspeedtable]': 'ev[freeFlowSpeedTable]',
  'ev[trafficspeedtable]': 'ev[trafficSpeedTable]',
  'ev[auxiliaryconsumption]': 'ev[auxiliaryConsumption]',
  'ev[highspeedthreshold]': 'ev[highSpeedThreshold]',
  'ev[chargingcurve]': 'ev[chargingCurve]',
  'ev[maxcharge]': 'ev[maxCharge]',
  'ev[initialcharge]': 'ev[initialCharge]',
  'ev[maxchargingvoltage]': 'ev[maxChargingVoltage]',
  'ev[maxchargingcurrent]': 'ev[maxChargingCurrent]',
  'ev[maxpoweratlowvoltage]': 'ev[maxPowerAtLowVoltage]',
  'ev[maxchargeafterchargingstation]': 'ev[maxChargeAfterChargingStation]',
  'ev[minchargeatchargingstation]': 'ev[minChargeAtChargingStation]',
  'ev[minChargeAtFirstChargingStation]': 'ev[minChargeAtFirstChargingStation]',
  'ev[minchargeatdestination]': 'ev[minChargeAtDestination]',
  'ev[chargingsetupduration]': 'ev[chargingSetupDuration]',
  'ev[preferredbrands]': 'ev[preferredBrands]',
  'ev[preferredchargepointoperators]': 'ev[preferredChargePointOperators]',
  'ev[emobilityserviceproviderpreferences]': 'ev[eMobilityServiceProviderPreferences]',
  'ev[excludechargingstations]': 'ev[excludeChargingStations]',
  'ev[driveefficiency]': 'ev[driveEfficiency]',
  'ev[recuperationefficiency]': 'ev[recuperationEfficiency]',
  'ev[auxiliarypowerconsumption]': 'ev[auxiliaryPowerConsumption]',
};

const postDataFormatter = {
  'ev[preferredBrands]': (value) => value.split(','),
};

const postDataParser = {
  'ev[preferredBrands]': (value) => value.join(','),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, postData = {} }) => {
    const result = {};

    Object.keys(parsedParams).forEach((param) => {
      if (evParamMask.test(param) || Object.keys(evParamsMap).indexOf(param.toLowerCase())>-1) {
        const resKey = evParamsMap[param] || param;
        if (param === 'ev[connectortypes]' && parsedParams[param].length) {
          result[resKey] = parsedParams[param].split(',');
        } else {
          result[resKey] = parsedParams[param];
        }
        delete parsedParams[param];
      }
    });

    if (postData.ev) {
      Object.keys(postData.ev).forEach((key) => {
        const resKey = `ev[${key}]`;
        const parser = postDataParser[resKey];
        result[resKey] = parser ? parser(postData.ev[key]) : postData.ev[key];
        if (!result.evPost) {
          result.evPost = {};
        }
        result.evPost[resKey] = true;
      });
    }

    result.evEnabled = !!Object.keys(result).length;
    return { ...empty, ...result };
  },
  defaultState: () => ({
    ...empty,
  }),
  getRequestOptions: (fields) => {
    if (fields.evEnabled) {
      let params = omit(fields, 'evEnabled', 'evPost');
      let data = {};
      const keys = Object.keys(params);
      keys.forEach((key) => {
        if ((evParamMask.test(key) || Object.keys(evParamsMap).indexOf(key.toLowerCase())>-1) && params[key].length !== 0) {
          if (fields.evPost[key]) {
            const formatter = postDataFormatter[key];
            set(data, key, formatter ? formatter(params[key]) : params[key]);
            delete params[key];
          } else if (isArray(params[key])) {
            params[key] = params[key].join(',');
          }
        } else {
          delete params[key];
        }
      });
      const res = { params };
      if (Object.keys(data).length > 0) {
        res.data = data;
        res.method = 'post';
      }
      return res;
    } else {
      return {};
    }
  },
  Component: ConsumptionView,
};
