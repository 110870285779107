import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import {
  FormRow,
  Textarea,
  TabsControl,
  Select,
  CloseButton,
  ExpandFormRow
} from '@here/ref-client-ui';
import './violationMapping.scss';
import isArray from 'lodash/isArray';
import every from 'lodash/every';
import { createSelector } from 'reselect';
import adjustLUIStyle from '../../../utils/adjustLUIStyle';


const CATEGORY_OPTIONS = [
  {label: 'Category 1', value: '1'},
  {label: 'Category 2', value: '2'},
];

const TYPE_OPTIONS = [
  {label: 'Restriction', value: 'restriction'},
  {label: 'Traffic', value: 'traffic'},
];

const VIOLATION_TRAFFIC_OPTIONS = [
  {label: 'Bridge', value: 'bridge'},
];

const VIOLATION_RESTRICTION_OPTIONS = [
  {label: 'Bridge', value: 'bridge'},
  {label: 'Gross Weight', value: 'grossWeight'},
  {label: 'Current Weight', value: 'currentWeight'},
  {label: 'Height', value: 'height'},
  {label: 'Time Dependent', value: 'timeDependent'},
];

const VIOLATION_OPTIONS = [
  {label: 'Include', value: 'include'},
  {label: 'Exclude', value: 'exclude'},
  {label: 'Ignore', value: 'ignore'},
];

const isItemValid = (item) => 
  !!CATEGORY_OPTIONS.filter(option => parseInt(option.value) === item['category']).length &&
  !!TYPE_OPTIONS.filter(option => option.value === item['type']).length &&
  Object.keys(item).filter(key => 
      VIOLATION_RESTRICTION_OPTIONS.filter(option => option.value === key).length === 1 && 
      VIOLATION_OPTIONS.filter(option => option.value === item[key]).length === 1
    ).length === Object.keys(item).length-2


const isValid = createSelector(
  (items) => items,
  (items) => {
    if (!isArray(items)) {
      return false;
    }
    return every(items, isItemValid);
  }
);


class ViolationMapping extends React.Component {
  static MappingTabs = {
    TEXT_AREA: 'textArea',
    ENTRIES: 'entries',
  };

  state = {
    // eslint-disable-next-line no-use-before-define
    currentEntriesTab: ViolationMapping.MappingTabs.ENTRIES,
  };
  
  componentDidMount () {
    adjustLUIStyle(document.querySelectorAll('.rf-add-point'), 'lui-button');
  }

  componentDidUpdate () {
    adjustLUIStyle(document.querySelectorAll('.rf-add-point'), 'lui-button');
  }

  onCategoryChange = (num, e) => {
    const { items, onChange } = this.props;
    const category = parseInt(utils.extractData(e));
    if (category) {
      items[num] = { ...items[num], ...{ category }};
      onChange(items);
    } else {
      delete items[num].category;
      onChange(items);
    }
  };

  onTypeChange = (num, e) => {
    const { items, onChange } = this.props;
    const type = utils.extractData(e);
    if (type) {
      items[num] = { ...items[num], ...{ type }};
      onChange(items);
    } else {
      delete items[num].type;
      onChange(items);
    }
  };

  onViolationChange = (num, e, key) => {
    const { items, onChange } = this.props;
    const v = utils.extractData(e);

    if (v) {
      items[num][key] = v;
      onChange(items);
    } else {
      delete items[num][key];
      onChange(items);
    }
  };

  onRemoveItem = (num) => {
    const { items, onChange } = this.props;
    items.splice(num, 1);
    onChange(items);
  };

  onAddItem = () => {
    const { items, onChange } = this.props;
    items.push({});
    onChange(items);
  };

  setItems = (e) => {
    const { onChange } = this.props;
    try {
      const items = e.target.value.replace(/,\s*}/g, "}"); // remove trailing comma
      onChange(JSON.parse(items));
    } catch (err) {
      // Just ignore, the textarea will be cleaned
    }
  };

  getTextAreaEl = () => {
    const { items } = this.props;
    let itemsValue = JSON.stringify(items);
    
    return (
      <Textarea
        value={itemsValue}
        onBlur={this.setItems}
        isValid={isValid(items)}
      />
    );
  };

  getViolationEle = (violations, num) => {
    const { type } = violations;
    const options = type === 'traffic' ? VIOLATION_TRAFFIC_OPTIONS : VIOLATION_RESTRICTION_OPTIONS;

    return (
    options.map(({label, value}, idx) => {
      return (
        <FormRow className="rf-violation-item" key={idx+'violation'}>
        <Select
          className="rf-clearfix rf-violation-item__select"
          label={label}
          options={VIOLATION_OPTIONS}
          value={violations[value] || ''}
          addEmptyOption
          onChange={(e) => this.onViolationChange(num, e, value)}
        />
      </FormRow>)
    }))
  }

  getMappingEl = () => {
    const { items } = this.props;

    return (
      <div>
        <div>
          {items.map((item, num) => {
            const { category = '', type = '' } = item;
            return (
              <div key={num}>
                <FormRow className="rf-violation-item">
                  <Select
                    className="rf-clearfix rf-violation-item__select"
                    label={`Category`}
                    options={CATEGORY_OPTIONS}
                    value={category+''}
                    onChange={(e) => this.onCategoryChange(num, e)}
                    addEmptyOption
                  />     
                  <CloseButton onClick={(e) => this.onRemoveItem(num, e)} />
                </FormRow>
                <FormRow className="rf-violation-item">
                  <Select
                    className="rf-clearfix rf-violation-item__select"
                    label={`Type`}
                    options={TYPE_OPTIONS}
                    value={type}
                    onChange={(e) => this.onTypeChange(num, e)}
                    addEmptyOption
                  />
                </FormRow>
                <ExpandFormRow
                  className="rf-advanced-sub-params"
                  label="Violation Mapping Item"
                >
                  {this.getViolationEle(item, num)}
                </ExpandFormRow>
              </div>
            );
          })}
        </div>
        <FormRow>
          <div className="rf-clearfix">
            <lui-button class="rf-add-point" onClick={this.onAddItem}>
              Add Entry
            </lui-button>
          </div>
        </FormRow>
      </div>
    );
  };

  render() {
    const { currentEntriesTab } = this.state;
    const { items } = this.props;

    const tab =
      items.length > 5
        ? ViolationMapping.MappingTabs.TEXT_AREA
        : currentEntriesTab;

    const entriesViewEl =
      tab === ViolationMapping.MappingTabs.ENTRIES
        ? this.getMappingEl()
        : this.getTextAreaEl();
    const tabsData = [
      { label: 'Entries', name: ViolationMapping.MappingTabs.ENTRIES },
      { label: 'Text', name: ViolationMapping.MappingTabs.TEXT_AREA },
    ];

    return (
      <div className="rf-violation-mapping">
        <TabsControl
          tabsData={tabsData}
          currentTabName={tab}
          onChange={(e) => this.setState({ currentEntriesTab: e })}
        />
        {entriesViewEl}
      </div>
    );
  }
}

ViolationMapping.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object,
};

export default ViolationMapping;
