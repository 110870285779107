import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import { restoreFromUrl } from 'state/actions';
import { getIsLoading } from 'state/tabs/tab/isLoading';
import ApiUrlInputContainer from './ApiUrlInputContainer';

import './styles/apiUrl.scss';
import { getTitle } from 'state/tabs/tab/title';
import { getHistory } from 'state/tabs/tab/history';
import { getSelectedTabs } from 'state/tabs';
import { getTitleAndPreset, getFields } from 'utils/url/parser/parseHref';
import isFormPerformedForRequest from 'utils/isFormPerformedForRequest';
import { setIsHistoryShown, setTriggerAutoZoom, setIsCopyCURL } from 'state/ui/reducer';
import { getSelectedHistory } from 'state/tabs/tab/selectedHistory';
import { setSelectedHistory } from 'state/tabs/tab/selectedHistory/actions';
import { getConfig } from 'state/config';
import { set as setNotification } from 'state/notification/actions';
import { getIsHistoryShown, getIsCopyCURL } from 'state/ui';
import { fetchData, rawRequest, rawRequestWithAuth, fetchSettings } from 'state/tabs/tab/response/actions';
import { getAllSettings } from 'state/settings';
import CustomSettingsModal from './CustomSettingsModal';
import { RESTORE_URL_TYPE } from './constants';
import { addSettings } from 'state/settings/custom/reducer';
import RestoreFromDeepUrl from './RestoreFromDeepUrl';
import { push } from 'connected-react-router';
import { debounce } from 'lodash';
import isEqual from 'lodash/isEqual';
import { setSettings } from 'state/appSettings/actions';
import { getAppSettings } from '../../state/appSettings';
import { parseQuickUrl } from '../../utils/url';
import {
  getTabParamsMapping,
  setTabParamsMapping,
} from '../../state/tabs/tab/paramsMapping/reducer';

const getPostDataParam = (postData) => {
  if (postData && Object.keys(postData).length > 0) {
    return `&postData=${JSON.stringify(postData)}`;
  }
  return '';
};

const getRcParamsParam = (rcParams) => {
  if (rcParams && Object.keys(rcParams).length > 0) {
    return `&rcParams=${JSON.stringify(rcParams)}`;
  }
  return '';
};

export class ApiUrlContainer extends Component {
  constructor(props) {
    super(props);
    this.sendRequestDebounced = debounce(this.sendRequest, 500);
    ApiUrlContainer.smartRequest = this.smartRequest.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !prevState.isShown &&
      nextProps.isAutoRefreshOn &&
      nextProps.tabData.fields !== prevState.fields &&
      nextProps.selectedTab === prevState.selectedTab &&
      ApiUrlContainer.historyCheck(
        prevState.selectedHistory,
        nextProps.selectedHistory
      )
    ) {
      if (nextProps.selectedHistory !== -1) {
        nextProps.setSelectedHistory(-1);
      }
      ApiUrlContainer.smartRequest(nextProps);
    }

    const resState = {
      fields: nextProps.tabData.fields,
      selectedTab: nextProps.selectedTab,
      selectedHistory: nextProps.selectedHistory,
    };

    if (nextProps.paramsMapping) {
      resState.apiUrl = nextProps.apiUrl;
      resState.postData = nextProps.postData;
    }

    return resState;
  }

  static historyCheck(oldSelectedHistory, newSelectedHistory) {
    const isSelectedHistoryChanged = oldSelectedHistory !== newSelectedHistory;
    const isHistorySelected =
      oldSelectedHistory === -1 && newSelectedHistory > -1;
    const isHistoryReselected =
      oldSelectedHistory > -1 &&
      newSelectedHistory > -1 &&
      isSelectedHistoryChanged;
    return !isHistorySelected && !isHistoryReselected;
  }

  state = {
    isShown: false,
    apiUrl: null,
    selectedTab: null,
  };

  componentDidMount() {
    const {
      urlRouter: { hash },
      push,
      apiUrl,
      postData,
      rcParams,
      setNotification,
    } = this.props;
    if (hash.startsWith('#url')) {
      let url = null;
      try {
        url = decodeURIComponent(hash);
      } catch (e) {
        setNotification({
          title: 'Restore failure',
          message: `Failed to restore state from url: ${e.message}`,
          impact: 'negative',
          autoDismiss: 0,
        });
        push('');
        return;
      }
      const { apiUrl: hashApiUrl, postData: hashPostData, rcParams: hashRcParams } = parseQuickUrl(
        url,
        setNotification
      );

      if (url) {
        this.restoreFromUrl(
          {
            // We are masking default apikey for apikey rotation functionality which happens in ref-client-v8
            // build process
            // window.APIKEY and window.APIKEY2 are initialized in ref-client-v8 project index.html file
            url: hashApiUrl.replaceAll('DEFAULT_API_KEY', window.APIKEY),
            postData: hashPostData,
            rcParams: hashRcParams,
          },
          true
        );
      }
    } else {
      // We are masking default apikey for apikey rotation functionality which happens in ref-client-v8 build process
      // window.APIKEY and window.APIKEY2 are initialized in ref-client-v8 project index.html file
      const maskedUrl = apiUrl
        .replaceAll(window.APIKEY, 'DEFAULT_API_KEY')
        .replaceAll(window.APIKEY2, 'DEFAULT_API_KEY');
      push(`#url=${maskedUrl}${getRcParamsParam(rcParams)}${getPostDataParam(postData)}`);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      apiUrl,
      urlRouter: { hash: encodedUrlHash },
      routerAction,
      push,
      postData,
      rcParams,
      setNotification,
    } = this.props;

    let urlHash = null;
    try {
      urlHash = decodeURIComponent(encodedUrlHash);
    } catch (e) {
      push('');
      setNotification({
        title: 'Restore failure',
        message: `Failed to restore state from url: ${e.message}`,
        impact: 'negative',
        autoDismiss: 0,
      });
      return;
    }
    const isQuickUrl = urlHash.indexOf('#url=') === 0;
    const { apiUrl: hashApiUrl, postData: hashPostData, rcParams: hashRcParams } = parseQuickUrl(
      urlHash,
      setNotification
    );

    if (
      isQuickUrl &&
      routerAction === 'POP' &&
      prevProps.urlRouter.hash !== encodedUrlHash
    ) {
      // We are masking default apikey for apikey rotation functionality which happens in ref-client-v8 build process
      // window.APIKEY and window.APIKEY2 are initialized in ref-client-v8 project index.html file
      this.restoreFromUrl({
        url: hashApiUrl.replaceAll('DEFAULT_API_KEY', window.APIKEY),
        postData: hashPostData,
        rcParams: hashRcParams,
      });
      return;
    }
    // We are masking default apikey for apikey rotation functionality which happens in ref-client-v8 build process
    // window.APIKEY and window.APIKEY2 are initialized in ref-client-v8 project index.html file
    const maskedUrl = apiUrl
      .replaceAll(window.APIKEY, 'DEFAULT_API_KEY')
      .replaceAll(window.APIKEY2, 'DEFAULT_API_KEY');

    if (
      (isQuickUrl || !urlHash) &&
      ( hashApiUrl !== maskedUrl || !isEqual(postData, hashPostData) || !isEqual(rcParams, hashRcParams))
    ) {
      push(`#url=${maskedUrl}${getRcParamsParam(rcParams)}${getPostDataParam(postData)}`);
    }
  }

  componentWillUnmount() {
    const { push } = this.props;
    push('');
  }

  restoreFromUrl = ({ url, postData, rcParams }, skipNotification) => {
    const {
      configs,
      title,
      setNotification,
      restoreFromUrl,
      allSettings,
      setTriggerAutoZoom,
      paramsMapping,
      tabData
    } = this.props;
    if (!paramsMapping) {
      this.setState({ apiUrl: url, postData, rcParams });
      return;
    }
    const parseUrlData = getTitleAndPreset(url, allSettings, tabData.preset);
    if (!parseUrlData.title) {
      const { url, parsedParams, settings, probableTitle, urlParamsMap } = parseUrlData;
      this.setState({
        modal: { url, settings, params: parsedParams, probableTitle, urlParamsMap },
      });
    } else {
      const { preset, title: newTitle, parsedParams, url, urlParamsMap } = parseUrlData;
      const settings = allSettings[newTitle][preset];
      const inputs = configs[newTitle].inputs;
      let tabTitle = null;

      if (title !== newTitle) {
        tabTitle = configs[newTitle].tabTitle;
      }
      restoreFromUrl(
        newTitle,
        preset,
        getFields(settings, inputs, parsedParams, urlParamsMap, url, postData, rcParams),
        tabTitle
      );
      setTimeout(() => {
        setTriggerAutoZoom(new Date().getTime());
      }, 1);

      if (title !== newTitle && !skipNotification) {
        setNotification({
          message: `The module has been changed to "${newTitle}"`,
          impact: 'significant',
          autoDismiss: 4,
        });
      }
    }
  };

  onModalSave = ({ type, data }) => {
    let { selectedModule: title, selectedPreset: preset, settings } = data;
    const { restoreFromUrl, configs, addSettings, allSettings } = this.props;
    const {
      modal: { params, url, urlParamsMap },
    } = this.state;

    if (type === RESTORE_URL_TYPE.NEW) {
      addSettings(title, preset, settings);
    }

    settings = allSettings[title][preset] || settings;
    const inputs = configs[title].inputs;
    restoreFromUrl(title, preset, getFields(settings, inputs, params, urlParamsMap, url));
    this.setState({ modal: null });
  };

  onModalCancel = () => {
    this.setState({ modal: null });
  };

  sendRequest = () => {
    let { request, selectedTab, paramsMapping, rawRequestWithAuth } = this.props;
    const { apiUrl, postData } = this.state;
    if (!this.props.isLoading) {
      const cancelTokenSource = axios.CancelToken.source();
      this.setState({ cancelTokenSource });
      if (paramsMapping) {
        request(selectedTab, {
          cancelToken: cancelTokenSource.token,
        });
      } else {
        rawRequestWithAuth(apiUrl, postData, selectedTab, {
          cancelToken: cancelTokenSource.token,
        });
      }
    } else if (this.state.cancelTokenSource) {
      this.state.cancelTokenSource.cancel();
    }
    this.close();
  };

  getSetting = () => {
    const { selectedTab, fetchSettings } = this.props;
    return fetchSettings(selectedTab);
  }

  close = () => {
    this.setState({ isShown: false });
  };

  smartRequest = (nextProps) => {
    let { title, config, tabData } = nextProps;
    if (
      isFormPerformedForRequest(
        tabData,
        config[title].autoRefresh || {},
        this.props.tabData.fields
      )
    ) {
      this.sendRequestDebounced();
    }
  };

  render() {
    const {
      isLoading,
      isHistoryShown,
      setIsHistoryShown,
      isCopyCURL,
      setIsCopyCURL,
      configs,
      allSettings,
      setSettings,
      isApiUrlMultiline,
      postData,
      rcParams,
      setNotification,
      setTabParamsMapping,
      paramsMapping,
      apiUrl,
    } = this.props;

    const { modal } = this.state;
    return (
      <lui-default-theme>
        <div className="rf-api-url">
          <ApiUrlInputContainer
            configs={configs}
            sendRequest={this.sendRequest}
            apiUrl={this.state.apiUrl || apiUrl}
            postData={this.state.postData || postData}
            rcParams={this.state.rcParams || rcParams}
            apiUrlUpdate={this.restoreFromUrl}
            isLoading={isLoading}
            setAppSettings={setSettings}
            isApiUrlMultiline={isApiUrlMultiline}
            setNotification={setNotification}
            paramsMapping={paramsMapping}
            setTabParamsMapping={setTabParamsMapping}
            isCopyCURL={isCopyCURL}
            setIsCopyCURL={setIsCopyCURL}
            getSetting={this.getSetting}
          />
          <div
            className="rf-api-url__history-btn"
            onClick={() => setIsHistoryShown(!isHistoryShown)}
          >
            {isHistoryShown ? 'Hide' : 'Show'} history
            <i className="rf-api-url__history-btn__counter">
              {this.props.historyLength}
            </i>
          </div>
        </div>
        {modal && (
          <CustomSettingsModal
            configs={configs}
            allSettings={allSettings}
            onSave={this.onModalSave}
            onCancel={this.onModalCancel}
            {...modal}
          />
        )}
        <RestoreFromDeepUrl />
      </lui-default-theme>
    );
  }
}

function mapStateToProps(state) {
  return {
    title: getTitle(state),
    selectedTab: state.selectedTab,
    isLoading: getIsLoading(state),
    isAutoRefreshOn: state.appSettings.isAutoRefreshOn,
    isHistoryShown: getIsHistoryShown(state),
    isCopyCURL: getIsCopyCURL(state),
    selectedHistory: getSelectedHistory(state),
    historyLength: getHistory(state).length,
    configs: getConfig(state),
    allSettings: getAllSettings(state),
    // urlRouter: state.routing.locationBeforeTransitions,
    urlRouter: state.router.location,
    routerAction: state.router.action,
    config: getConfig(state),
    tabData: getSelectedTabs(state),
    isApiUrlMultiline: getAppSettings(state).isApiUrlMultiline,
    paramsMapping: getTabParamsMapping(state),
  };
}

const mapDispatchToProps = {
  restoreFromUrl,
  setIsHistoryShown,
  setSelectedHistory,
  setIsCopyCURL,
  setNotification,
  addSettings,
  request: fetchData,
  push: push,
  setTriggerAutoZoom,
  setSettings,
  setTabParamsMapping,
  rawRequest,
  rawRequestWithAuth,
  fetchSettings,
};

ApiUrlContainer.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  request: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  selectedTab: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isAutoRefreshOn: PropTypes.bool.isRequired,
  restoreFromUrl: PropTypes.func.isRequired,
  isHistoryShown: PropTypes.bool.isRequired,
  setIsHistoryShown: PropTypes.func.isRequired,
  setSelectedHistory: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  selectedHistory: PropTypes.number.isRequired,
  historyLength: PropTypes.number.isRequired,
  configs: PropTypes.object.isRequired,
  allSettings: PropTypes.object.isRequired,
  addSettings: PropTypes.func.isRequired,
  urlRouter: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  setTriggerAutoZoom: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  tabData: PropTypes.object.isRequired,
  isApiUrlMultiline: PropTypes.bool.isRequired,
  setSettings: PropTypes.func.isRequired,
  setTabParamsMapping: PropTypes.func.isRequired,
  paramsMapping: PropTypes.bool.isRequired,
  postData: PropTypes.object,
  rawRequest: PropTypes.func.isRequired,
  rawRequestWithAuth: PropTypes.func.isRequired,
  fetchSettings: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiUrlContainer);
