import { SET_RESULT } from '../tabs/tab/result/raw/constants';

const SET_LEFT_PANEL = `ui/SET_LEFT_PANEL`;
const SET_HISTORY_SHOWN = 'ui/SET_HISTORY_SHOWN';
const SET_NEW_TAB_SHOWN = 'ui/SET_NEW_TAB_SHOWN';
const SET_RESULT_PANEL_SHOWN = 'ui/SET_RESULT_PANEL_SHOWN';
const SET_TRIGGER_AUTO_ZOOM = 'ui/SET_TRIGGER_AUTO_ZOOM';
const SET_OPEN_IN_SHOWN = 'ui/SET_OPEN_IN_SHOWN';
const SET_AVOID_EXCLUDE_SHOWN = 'ui/SET_AVOID_EXCLUDE_SHOWN';
const SET_COPY_CURL = 'ui/SET_COPY_CURL';

const initState = {
  isLeftPanelShown: true,
  isHistoryShown: false,
  isNewTabShown: false,
  isResultPanelShown: true,
  triggerAutoZoom: null,
  isOpenInShown: false,
  isAvoidExcludeShown: false,
  isCopyCURL: false, 
};

export default function ui(state = initState, action) {
  switch (action.type) {
    case SET_LEFT_PANEL:
      return {
        ...state,
        isLeftPanelShown: action.payload,
      };
    case SET_HISTORY_SHOWN:
      return {
        ...state,
        isHistoryShown: action.payload,
      };
    case SET_NEW_TAB_SHOWN:
      return {
        ...state,
        isNewTabShown: action.payload,
        isLeftPanelShown: action.payload ? true : state.isLeftPanelShown,
      };
    case SET_RESULT_PANEL_SHOWN:
      return {
        ...state,
        isResultPanelShown: action.payload,
      };
    case SET_OPEN_IN_SHOWN:
      return {
        ...state,
        isOpenInShown: action.payload,
      };
    case SET_AVOID_EXCLUDE_SHOWN:
      return {
        ...state,
        isAvoidExcludeShown: action.payload,
      };
    case SET_RESULT:
      return {
        ...state,
        // isLeftPanelShown: true,
      };
    case SET_TRIGGER_AUTO_ZOOM:
      return {
        ...state,
        triggerAutoZoom: action.payload,
      };
    case SET_COPY_CURL:
      return {
        ...state,
        isCopyCURL: action.payload,
      }
    default:
      return state;
  }
}

export function setIsLeftPanelShown(isShown) {
  return { type: SET_LEFT_PANEL, payload: isShown };
}

export const setIsHistoryShown = (isShown) => ({
  type: SET_HISTORY_SHOWN,
  payload: isShown,
});
export const setIsNewTabShown = (isShown) => ({
  type: SET_NEW_TAB_SHOWN,
  payload: isShown,
});
export const setIsResultPanelShown = (isShown) => ({
  type: SET_RESULT_PANEL_SHOWN,
  payload: isShown,
});
export const setIsOpenInShown = (isShown) => ({
  type: SET_OPEN_IN_SHOWN,
  payload: isShown,
});
export const setIsAvoidExcludeShown = (isShown) => ({
  type: SET_AVOID_EXCLUDE_SHOWN,
  payload: isShown,
});
export const setTriggerAutoZoom = (time) => ({
  type: SET_TRIGGER_AUTO_ZOOM,
  payload: time,
});
export const setIsCopyCURL = (isCopyCURL) => ({
  type: SET_COPY_CURL,
  payload: isCopyCURL,
});
