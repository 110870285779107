import React, { Component } from 'react';
import { FormRow, Input, TabsControl, Button } from '@here/ref-client-ui';
import { isEqual } from 'lodash';
import BoundingBox from '../../core/formViewsPlugins/regionDefinition/BoundingBox';

const toFixed6 = (val) => +val.toFixed(6);

const omitModes = [
  { label: 'Avoid', name: 'avoid' },
  { label: 'Exclude', name: 'exclude' }
];
const areasTypes = [
  { label: 'Bounding Box', name: 'bbox' },
  { label: 'Polygon', name: 'polygon' },
  { label: 'Corridor', name: 'corridor' },
];

export default class AvoidExcludeForm extends Component {
  constructor(props) {
    const { box, line, zoom } = props;
    const { topLeft, bottomRight } = box;
    const { left, right } = line;
    const radius = Math.max(Math.pow(2, 14-zoom) * 100, 100);
    super(props);
    this.formRef = React.createRef();
    this.state = {
      bbox: [
        [toFixed6(topLeft.lat), toFixed6(topLeft.lng)],
        [toFixed6(bottomRight.lat), toFixed6(bottomRight.lng)]
      ],
      polygon: [
        [toFixed6(topLeft.lat), toFixed6((topLeft.lng+bottomRight.lng)/2)],
        [toFixed6(bottomRight.lat), toFixed6(topLeft.lng)],
        [toFixed6(bottomRight.lat), toFixed6(bottomRight.lng)],
      ],
      corridor: [
        [toFixed6(left.lat), toFixed6(left.lng)],
        [toFixed6(right.lat), toFixed6(right.lng)],
      ],
      radius: radius
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props, nextProps) || 
      !isEqual(this.state, nextState)
    )
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.checkBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.checkBlur);
  }

  checkBlur = (e) => {
    let { onBlur } = this.props;
    if (!this.formRef.current.contains(e.target)) {
      onBlur(e);
    }
  };

  onChange = (e, key) => {
    const { onChangeState } = this.props;
    const value = e.target ? e.target.value : e;
    onChangeState(key, value);
  }

  onChangeBbox = (bbox) => {
    this.setState({ bbox });
  }

  onChangePolyline = (e, idx) => {
    const value = e.target ? e.target.value : e;
    const { polygon } = this.state;
    polygon[idx] = value.split(',');
    this.setState({ polygon });
  }

  onChangeCorridor = (e, idx) => {
    const value = e.target ? e.target.value : e;

    if (idx === 'radius') {
      this.setState({ radius: value });
    } else {
      const { corridor } = this.state;
      corridor[idx] = value.split(',');
      this.setState({ corridor });
    }
  }

  add = () => {
    let { onBlur, currentArea, addArea } = this.props;
    const { bbox, polygon, corridor, radius } = this.state;
    if (currentArea === 'bbox') {
      addArea(bbox);
    } else if (currentArea === 'polygon') {
      addArea(polygon);
    } else {
      addArea(corridor, radius);
    }
    onBlur();
  }

  getCoordinatesEle = () => {
    const { currentArea } = this.props;
    const { bbox, corridor, polygon, radius } = this.state;

    if (currentArea === 'bbox') {
      return (
        <BoundingBox
          bounds={bbox}
          onChange={this.onChangeBbox}
        />
      )
    } else if (currentArea === 'polygon') {
      return (<>
        <FormRow>
          <Input
            type="text"
            label="1st Coordinate"
            value={`${polygon[0]}`}
            blurOnEnter
            onChange={(e) => this.onChangePolyline(e, 0)}
          />
        </FormRow>
        <FormRow>
          <Input
            type="text"
            label="2nd Coordinate"
            value={`${polygon[1]}`}
            blurOnEnter
            onChange={(e) => this.onChangePolyline(e, 1)}
          />
        </FormRow>
        <FormRow>
          <Input
            type="text"
            label="3rd Coordinate"
            value={`${polygon[2]}`}
            blurOnEnter
            onChange={(e) => this.onChangePolyline(e, 2)}
          />
        </FormRow>
      </>)
    } else {
      return (<>
        <FormRow>
          <Input
            type="text"
            label="left Coordinate"
            value={`${corridor[0]}`}
            blurOnEnter
            onChange={(e) => this.onChangeCorridor(e, 0)}
          />
        </FormRow>
        <FormRow>
          <Input
            type="text"
            label="right Coordinate"
            value={`${corridor[1]}`}
            blurOnEnter
            onChange={(e) => this.onChangeCorridor(e, 1)}
          />
        </FormRow>
        <FormRow>
          <Input
            type="number"
            label="Radius"
            value={radius}
            blurOnEnter
            onChange={(e) => this.onChangeCorridor(e, 'radius')}
          />
        </FormRow>
      </>)

    }
  }

  render() {
    const { currentMode, currentArea, skipCorridor } = this.props;

    return (
      <div className="rf-map__avoidexclude-module" ref={this.formRef}>
        <h2>Add Avoid/Exclude Area </h2>
        <FormRow className='rf-map__avoidexclude-tabs'>
          <TabsControl
            tabsData={omitModes}
            currentTabName={currentMode}
            onChange={(e) => this.onChange(e, `currentMode`)}
          />
        </FormRow>
        <FormRow className='rf-map__avoidexclude-tabs'>
          <TabsControl
            tabsData={skipCorridor ? areasTypes.slice(0, 2) : areasTypes}
            currentTabName={currentArea}
            onChange={(e) => this.onChange(e, `currentArea`)}
          />
        </FormRow>
        { this.getCoordinatesEle()}
        <FormRow >
          <Button onClick={() => this.add()} title="Add" />
        </FormRow>
      </div>
    );
  }
}
